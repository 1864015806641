.post-card img {
	width: 100%;
	filter: grayscale(1) opacity(.8);
	transition: all .6s ease-in-out;
}

.post-card img:hover {
	filter: grayscale(0) opacity(1);
	transition: all .6s ease-in-out;
}

.post-card h3 {
	font-size: 1.3rem;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
}

.post-card p {
	padding-left: 10px;
	padding-right: 10px;
	
}

.post-title {
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	width: 100%;
	height: 90px;
}

.post-title h3 {
	padding-left: 40px;
	padding-right: 40px;
}

.post-desc {
	--max-lines: 4;
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--max-lines);
	font-weight: 600;
	font-size: 1.1rem;
	line-height: 1.5rem;
	text-align: center;
}

.post-tags {
	height: 40px;
	color: orange;
	font-size: .9rem;
	text-align: center;
}

.post-meta {
	font-size: .9rem;
	text-align: center;
}

.post-meta svg {
	width: 12px;
	margin-right: 10px;
	margin-bottom: -3px
}

.post-meta span {
	margin-left: 15px;
	margin-right: 15px;
	font-weight: 700;
}

.blog-btn {
	width: 100%;
	text-align: center;
	font-weight: 700;
	font-size: 1.1rem;
	transition: all .6s ease-in-out;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.blog-btn a {
	display: block;
	width: 100%;
	height: 50px;
	line-height: 50px;
	background-color: white;
	color: black;
	transition: all .6s ease-in-out;
	text-decoration: none;
	border-radius: 0 0 20px 20px;
}

.blog-btn a:hover {
	color: white;
	background-color: black;
	transition: all .6s ease-in-out;
}

.blog-post {
	margin-bottom: 50px;
}

.post-title {
	margin-top: 0px;
}

.post-content {
	padding-right: 20px;
}

.post-content p {
	line-height: 1.7rem;
}

.post-content h2 {
	font-size: 1.5rem;
    margin-block-end: -5px;
}

.blog-gallery {
	position: relative;
	margin-top: 1.5em;
}

.blog-gallery > .gallery-grid {
	position: sticky;
	column-count: auto;
	-moz-column-fill: balance;
    column-fill: balance;
}

/* add post */

.add-post {
	width: 700px;
}

.add-post label {
	display: flex;
	flex-direction: column;
	width: 700px;
	margin-top: 20px;
	word-wrap: break-word;
}

.add-post input {
	width: 100%;
	height: 40px;
}

.text-area {
	min-height: 350px;
}

.add-post span {
	font-size: 1.2rem;
	font-weight: 500;
	margin-bottom: 5px;
}

.add-post button {
	width: 250px;
	height: 40px;
	font-size: 1.3rem;
	font-weight: 700;
	margin-top: 30px;
	margin-bottom: 60px;
}

.post-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	column-gap: 20px;
	margin-bottom: 50px;
}

.post-card {
	width: 30%;
	margin: 10px;
	margin-top: 30px;
	border: 1px solid rgb(121, 121, 121);
	border-radius: 20px;
}

.blog-tag-name {
	font-size: .9rem;
	color: orange;
	text-align: center;
	text-decoration: none;
	font-weight: 600;
	margin-left: 15px;
	margin-right: 15px;
	transition: all .7s ease-in-out;
	border: 1px solid orange;
	background-color: white;
	padding: 10px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 25px;
	margin-bottom: 20px;
	cursor: pointer;
}

.blog-tag-name-active {
	font-size: .9rem;
	color: black;
	text-align: center;
	text-decoration: none;
	font-weight: 600;
	margin-left: 15px;
	margin-right: 15px;
	transition: all .7s ease-in-out;
	border: 1px solid orange;
	background-color: orange;
	padding: 10px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 25px;
	margin-bottom: 20px;
	cursor: pointer;
}

.blog-tag-name:hover {
	color: rgb(39, 39, 39);
	background-color: orange;
	transition: all .7s ease-in-out;
}

.blog-heading-tags {
	margin-bottom: 50px;
}

.pagin-btn {
	font-size: 1.1rem;
	color: orange;
	text-align: center;
	text-decoration: none;
	font-weight: 600;
	margin-right: 15px;
	transition: all .7s ease-in-out;
	border: 1px solid orange;
	background-color: white;
	padding: 10px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 25px;
	margin-bottom: 20px;
	cursor: pointer;
}

.pagin-btn:hover {
	color: rgb(39, 39, 39);
	background-color: orange;
	transition: all .7s ease-in-out;
}

#pagination {
	margin-top: 45px;
}

.post-tags {
	color: orange;
}

.post-avatar {
	margin-top: 50px;
}

.post-avatar img {
	width: 50%;
	border-radius: 50%;
	border: 1px solid orange;
}

.blog-heading-h {
	margin-top: 50px;
}

.blog-heading-p p {
	font-weight: 600;
}

.blog-post > .post-title {
	text-align: center;
}

/* responsive */

@media screen and (max-width: 1350px) {
	.post-card {
		width: 46%;
	}
}

@media screen and (max-width: 1050px) {
	.post-card {
		width: 75%;
	}

	.post-content {
		margin: 0 auto;
		padding-right: 5px;
		padding-left: 5px;
	}

	.blog-heading-section {
		max-width: 95vw;
		margin: 0 auto;
	}



	.blog-heading-img img {
		max-width: 100%;
	}

}

@media screen and (max-width: 500px) {
	.post-card {
		width: 85%;
	}
}