/* ---Layuot--- */
.flex {
	display: flex;
}

.row {
	flex-direction: row;
}

.column {
	flex-direction: column;
}

.wrap {
	flex-wrap: wrap;
}

.justify-center {
	justify-content: center;
}

.justify-start {
	justify-content: start;
}

.justify-end {
	justify-content: end;
}

.justify-between {
	justify-content: space-between;
}

.align-center {
	align-items: center;
}

.align-start {
	align-content: start;
}

.align-end {
	align-content: end;
}

.align-between {
	align-content: space-between;
}

.left {
	align-self: end;
}

.full-width {
	width: 100%;
}

.fixed-width {
	max-width: 1200px;
}

.fixed-width-700 {
	max-width: 700px;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.w-10 {
	width: 10%;
}

.w-20 {
	width: 20%;
}

.w-30 {
	width: 30%;
}

.w-40 {
	width: 40%;
}

.w-50 {
	width: 50%;
}

.w-60 {
	width: 60%;
}

.w-70 {
	width: 70%;
}

.max-w-40 {
	max-width: 40%;
}

.max-w-60 {
	max-width: 60%;
}

.top-15 {
	margin-top: 15px;
}

.top-30 {
	margin-top: 30px;
}

.top-50 {
	margin-top: 50px;
}

.top-70 {
	margin-top: 70px;
}

.bottom-15 {
	margin-bottom: 15px;
}

.bottom-30 {
	margin-bottom: 30px;
}

.bottom-50 {
	margin-bottom: 50px;
}

.bottom-70 {
	margin-bottom: 70px;
}

.left-20 {
	padding-left: 20px;
}

.m-top-80 {
	margin-top: 80px;
}

.m-top-40 {
	margin-top: 80px;
}

.gallery-grid {
	column-count: auto;
	column-width: 250px;
	column-gap: 5px;
}

.gallery-grid img {
	width: 100%;
}

.lightbox-img {
	max-height: 80vh;
	max-width: 80vw;
	z-index: 999;
	-webkit-box-shadow: 0px 15px 22px -12px rgba(15, 15, 15, 1);
	-moz-box-shadow: 0px 15px 22px -12px rgba(15, 15, 15, 1);
	box-shadow: 0px 15px 22px -12px rgba(15, 15, 15, 1);
}

.width-80 {
	width: 60vw;
	color: white;
}

.width-80 h2 {
	cursor: pointer;
}

.colSize-1 {
	column-width: 100%;
	column-gap: 5px;
}

.colSize-2 {
	column-width: 180px;
	column-gap: 5px;
}

.colSize-3 {
	column-width: 120px;
	column-gap: 5px;
}

/* responsive */

@media screen and (max-width: 900px) {
  .res-column {
		flex-direction: column;
	}

	.res-inver {
		flex-direction: column-reverse;
	}

	.fixed-width {
		max-width: 100%;
		padding-left: 10px;
		padding-right: 10px;
	}

	.fixed-width-700 {
		max-width: 90%;
	}

	.w-40, .w-50, .w-60, .w-70 {
		width: 100%;
	}

	.max-w-40 {
		max-width: 100%;
	}
	
	.max-w-60 {
		max-width: 100%;
	}

	.left-20 {
		padding: 0px;
	}

  .text-right {
		text-align: left;
	}

  .res-center {
		align-items: center;
		justify-content: center;
		text-align: center;
		margin: 0px;
		padding: 0px;
	}

  .justify-end {
		justify-content: center;
	}

	.justify-start {
		justify-content: center;
	}

	.align-end {
		align-items: center;
	}

	.align-start {
		align-items: center;
	}
}