.footer-01 {
	width: 33%;
}

.footer-02 {
	width: 33%;
}

.footer-03 {
	width: 33%;
}

.footer-02 img {
	max-width: 80%;
}

.footer-icons img {
	width: 20px;
}

.footer-icons .social-span {
	display: none;
}

#footer {
	padding-top: 30px;
	padding-bottom: 20px;
	background-color: black;
	color: white;
}

#footer p {
	font-size: 0.8rem;
}

#footer h2 {
	font-size: 1.1rem;
}

#footer img {
	filter: invert(1);
}

#footer li {
	margin-left: 10px;
	list-style: none;
}

#footer ul {
	padding: 0px;
	margin: 0px;
}

/* responsive */

@media screen and (max-width: 900px) {
  .footer-02 {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.footer-02 img {
		max-width: 80%;
	}

  .footer-01 {
		width: 100%;
	}
	
	.footer-02 {
		width: 100%;
	}
	
	.footer-03 {
		width: 100%;
	}
}

.copy {
	margin-top: 20px;
	text-align: center;
}

.footer-03 ul {
	flex-wrap: wrap;
}

.footer-03 li {
	margin-bottom: 15px;
}