.navigation {
	list-style: none;
	width: 100%;
	margin: 0px;
	color: #fff;
	z-index: 5;
}

.navigation img {
	width: 250px;
	margin-top: 10px;
}

.navigation ul {
	list-style: none;
	width: 100%;
	padding: 0px;
}

.navigation li {
	margin: 0 13px;
}

.navigation a {
	font-size: 1.1rem;
	font-weight: 500;
}

@media (max-width: 1366px) {
	.navigation li {
		margin: 0 10px;
		font-size: 12px;
	}
}

.logo img {
	width: 280px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.header-box {
	background-color: #000000;
	position: sticky;
  top: 0;
  z-index: 10;
}

.header {
	max-width: 1300px;
}

.menu-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 300px;
	background-color: #1d1d1d70;
	backdrop-filter: blur(5px);
	color: white;
	border: 1px solid gray;
}

.menu-box > .navigation {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px;
	padding: 0px;
}

.menu-box > .navigation li {
	margin-left: 0px;
	margin-right: 0px;
	margin-top: 15px;
	font-size: 1.3rem;
}

.menu-box > .footer-icons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	width: 170px;
	margin-top: 30px;
}

.menu-box > .footer-icons img {
	filter: invert(1);
}

.menu-box h2 {
	font-weight: 300;
	font-size: 1.2rem;
	cursor: pointer;
}

.header h3 {
	color: white;
	font-weight: 500;
	cursor: pointer;
}

.header-icons {
	margin-left: 20px;
}

.header-icons svg {
	height: .8em;
	transition: all .7s ease-out
}

.header-icons a {
	width: 20px;
}

.header-icons > .social-icons > .social-span {
	display: none;
}

#header > div > div.flex.justify-between.align-center.menu-desktop > div.header-icons > div > a > span.social-span {
	display: none;
}

@media screen and (min-width: 1050px) {
	.mobile-menu-icon {
		display: none;
	}

	
	
}

.navigation a {
	color: white;
	text-decoration: none;
	transition: all .7s ease-out
}

.navigation a:hover {
	color: orange;
	transition: all .7s ease-out
}

.mobile-nav-holder ul {
	flex-direction: column;
	margin-left: 0px;
	padding-left: 0px;
}

.mobile-nav-holder li {
	font-size: 1.2rem;
	margin-top: 10px;
}

@media screen and (max-width: 1050px) {
	.menu-desktop {
		display: none;
	}
	
	.logo img {
    width: 240px;
    margin-top: 15px;
    /* margin-bottom: 15px; */
	}
}