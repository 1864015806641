.hero-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  max-width: 1400px;
  margin: 2rem auto;
}

.hero-header h4 {
  color: orange;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: -1.8rem;
  margin-right: 0;
  margin-left: 20%;
  margin-top: 0;
}

.hero-header h1 {
  position: relative;
  font-size: 5rem;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  z-index: 7;
}

.header-icon {
  position: absolute;
  top: 50%;
  translate: 30% -40%;
  display: inline;
  font-size: 3rem;
  color: orange;
}

.hero-text {
  font-size: 1.5rem;
  font-weight: 600;
}

.hero-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.hero-bottom {
  width: 100%;
}

.hero-top-left {
 width: 40%;
 padding-left: 5rem;
}

.hero-top-right {
  width: 60%;
  z-index: -10;
 }

.hero-img {
  max-width: 100%;
}

.hero-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  width: 100%;
  margin-top: 3rem;
}

.hero-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  color: black;
  border-radius: 10px;
}

.hero-card h2 {
  text-align: left;
  font-size: 2rem;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1rem;
  border-left: 3px solid orange;
}

.hero-card h4 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.hero-buttons {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  margin-top: 3rem;
}

.hero-btn {
  padding: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  -webkit-box-shadow: 5px 3px 10px 0px rgba(190, 190, 190, 1);
-moz-box-shadow: 5px 3px 10px 0px rgba(190, 190, 190, 1);
box-shadow: 5px 3px 10px 0px rgba(190, 190, 190, 1);
}

.hero-btn-a {
  background-color: rgb(21, 106, 135);
  transition: all .4s ease;
}

.hero-btn-b {
  background-color: rgb(94, 33, 163);
  transition: all .4s ease;
}

.hero-btn-a:hover {
  background-color: rgb(175, 70, 0);
  transition: all .4s ease;
}

.hero-btn-b:hover {
  background-color: rgb(175, 70, 0);
  transition: all .4s ease;
}

.hero-btn a {
  text-decoration: none;
  color: white;
}

.hero-span {
  font-weight: 800;
  color: orange;
}

.hero-text strong {
  font-weight: 900;
}

.hero-mobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .hero-cards {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .hero-card {
    flex-basis: 40%;
  }

  .hero-top {
    flex-direction: column;
  }

  .hero-top-left {
    width: 80%;
    padding-left: 0rem;
   }
   
   .hero-top-right {
     width: 80%;
     z-index: -10;
    }
}

@media screen and (max-width: 600px) {

  .hero-card {
    flex-basis: 100%;
    margin: 0 5px;
  }
  
  .hero-header h1 {
    font-size: 2.5rem;
  }

  .hero-header h4 {
    font-size: 1rem;
  }

  .hero-text {
    font-size: 1rem;
  }

  .hero-card h2 {
    font-size: 1.5rem;
  }

  .hero-card h4 {
    font-size: 1.5rem;
  }

  .header-icon {
    position: absolute;
    top: 50%;
    translate: 30% -45%;
    display: inline;
    font-size: 2rem;
    color: orange;
  }

  .hero-header h4 {
    font-weight: 700;
    margin-bottom: -0.8rem;
    margin-right: 0;
    margin-left: 20%;
    margin-top: 0;
  }

  .hero-desktop {
    display: none;
  }

  .hero-mobile {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
  
}