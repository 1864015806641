@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* basic */
html {
	scroll-behavior: smooth;
	overflow-x: hidden;
	box-sizing: border-box;
}

body {
	position: relative;
	margin: 0px;
	padding: 0px;
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
}

h1 {
	font-size: 2.5rem;
}

h2 {
	font-size: 2.1rem;
}

p {
	font-size: 1.1rem;
	line-height: 1.7rem;
}

.modal-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.533);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	backdrop-filter: blur(5px);
}

/* main */
#main-paragraph p {
	padding-left: 15px;
}

.animate-header-box {
	position: relative;
}

.animate-header-wrapper {
	position: relative;
	margin-top: 42px;
	margin-bottom: 42px;
}

.animate-header {
	position: absolute;
	top: 28%;
	width: 100%;
	text-align: center;
	color: orange;
}

/* images */
.negative {
	filter: invert(1);
}

.mono {
	filter: grayscale(1);
	transition: all 1s ease;
}

.mono:hover {
	filter: grayscale(0);
	transition: all 1s ease;
}

/* start icons */
.icon-prop {
	max-width: 80px;
	min-height: 120px;
}

.icon-header {
	font-weight: 700;
	font-size: 1.6rem;
}

.icon-text {
	padding: 3px;
}

/* start offer */
.offer-row {
	margin-top: 30px;
	margin-bottom: 30px;
}

.offer-img {
	display: flex;
}

.offer-img img {
	width: 70%;
}

.offer-text p {
	max-width: 95%;
	padding: 5px;
}

.offer-text h2 {
	max-width: 95%;
	padding: 5px;
}

.offer-text > div > h2 > strong {
	color: orange;
}

/* social icons */
.social-icons {
	cursor: pointer;
}

.social-icons a {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 8px;
	text-decoration: none;
	font-family: 'Montserrat', sans-serif;
	color: #000000;
}

.social-icons span {
	text-decoration: none;
	font-family: 'Montserrat', sans-serif;
	color: #000000;
	margin-left: 15px;
	font-size: 22px;
	font-weight: 700;
}

.contact-icons svg {
	width: 40px;
	height: 40px;
}

.contact-icons > .social-span {
	line-height: 40px;
	display: flex;
  align-items: center;
	z-index: -2;
}

.contact-icons > .social-icon {
	line-height: 40px;
	display: flex;
  align-items: center;
	z-index: -2;
}

/* slider */
.slider {
	display: block;
	position: relative;
	height: 90vh;
	width: 100%;
	background-color: black;
}

.slider-inner {
	display: block;
	height: 90vh;
	width: 100%;
	position: relative;
	top: 0;
	left: 0;
}

.slider-img {
	display: block;
	height: 90vh;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}

.slider-background {
	display: block;
	height: 90vh;
	width: 100%;
	background-size: cover;
	background-position: center center;
	position: absolute;
	top: 0;
	left: 0;
}

.slider-holder {
	display: block;
	opacity: 0.01;
	width: 100%;
	height: 90vh;
}

/* counters */
#counters {
	background-image: url(../public/assets/bg1.jpg);
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
}

.counter-box {
	margin-top: 40px;
	margin-bottom: 40px;
	padding-left: 15px;
	padding-right: 15px;
}

.counter-box p,
.counter-box h2 {
	color: white;
}


.counter-box h2 {
	font-size: 2.7rem;
}

/* background */
.background-bottom {
	transform: rotate(180deg);
	margin-bottom: -2px;
}

.background-top {
	margin-top: -2px;
}

/* gallery */
.galery-item {
	margin-left: 10px;
	margin-right: 10px;
}

.galery-item img {
	border: 1px solid gray;
}

.galery-item img:hover {
	border: 1px solid orange;
}

/* portfolio */
#portfolio {
	background-image: url(../public/assets/bg2.jpg);
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
}

#portfolio p,
#portfolio h2 {
	color: white;
}

#portfolio h2 {
	margin-top: 20px;
}

.portfolio-img {
	margin-bottom: 45px;
}

#main {
	margin-top: 45px;
}

#bio {
	background-color: black;
	color: white;
}

.offer-text h3 {
	font-size: 1.8rem;
}

#przyslona {
	width: 50%;
}

#offer-list {
	margin-top: 80px;
}

.offer-img img {
	border: 1px solid black;
}

.offer-img img:hover {
	border: 1px solid orange;
}

#main #_galeria {
	margin-top: 70px;
}

.contact-icons svg {
	height: 50px;
}

.icon-text {
	line-height: 1.3em;
}

.icon-svg svg {
	height: 60px;
	width: 60px;
}

.icon-header {
	margin-top: 20px;
    margin-bottom: 15px;
}

.icon-text {
	margin-top: 0px;
	padding-left: 3px;
	padding-right: 3px;
}

.service-span {
	color: black;
	transition: all .7s ease-in-out;
}

.service-span{
	font-size: 1.9rem;
}

.service-span-active {
	color: orange;
	transition: all .4s ease-in-out;
}

/* responsive */
@media screen and (max-width: 900px) {
	.offer-text h2, .offer-text p {
		width: 100%;
		max-width: 100%;
	}

	.offer-text h2 {
		text-align: center;
	}

	.offer-text p {
		padding-right: 5px;
	}

	.animate-header h1 {
		font-size: 1.7rem;
	}

	.animate-header-wraper h1 {
		font-size: 1.7rem;
	}

	.animate-header-wrapper {
		position: relative;
		margin-top: 65px;
		margin-bottom: 62px;
	}

	.portfolio-img {
		width: 90%;
		max-width: 90%;
	}

	.header {
		max-width: 100%;
		min-width: 300px;
	}

	body {
		overflow-x: hidden;
	}

	.icon-header {
		margin-top: 5px;
		margin-bottom: 10px;
	}

	.icon-text {
		margin-top: 5px;
	}

	h1 {
		font-size: 1.8rem;
	}

	h2 {
		font-size: 1.8rem;
		margin-top: 0px;
		margin-bottom: 10px;
	}

	h3 {
		font-size: 1.5rem;
	}

	#bio h2 {
		margin-top: 30px;
	}

	.background-bottom, .background-top {
		display: none;
	}

	.service-span h3 {
		font-size: 1.5rem;
	}

	.offer-text h2 {
		padding: 0px;
	}

	.offer-img img {
		margin-bottom: 25px;
	}

	.slider {
		display: block;
		position: relative;
		height: 450px;
		width: 100%;
		background-color: black;
	}
	
	.slider-inner {
		display: block;
		height: 450px;
		width: 100%;
		position: relative;
		top: 0;
		left: 0;
	}
	
	.slider-img {
		display: block;
		height: 450px;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
	}
	
	.slider-background {
		display: block;
		height: 450px;
		width: 100%;
		background-size: cover;
		background-position: center center;
		position: absolute;
		top: 0;
		left: 0;
	}
	
	.slider-holder {
		display: block;
		opacity: 0.01;
		width: 100%;
		height: 450px;
	}

	.animate-header-box {
		margin-top: -60px;
	}

	#bio p, #bio h2 {
		text-align: center;
	}
}



