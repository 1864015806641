#bio-page {
	min-height: 80vh;
	margin-top: 30px;
	margin-bottom: 50px;
}

#bio-page .content-box {
	border: 1px solid black;
}

#bio-page .text-box {
	padding-left: 25px;
	padding-right: 15px;
}

#bio-page .img-box {
	padding: 0px;
	background-color: black;
}

#kontakt {
	min-height: 70vh;
	margin-top: 30px;
	margin-bottom: 60px;
}

#oferta img {
	width: 100%;
}